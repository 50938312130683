<script setup lang="ts">
import { Permission } from '~/types/misc'

const props = defineProps<{
  title?: string
}>()

const emit = defineEmits(['refreshLayout'])
const route = useRoute()
const nuxtApp = useNuxtApp()
const userStore = useUserStore()
const clinicStore = useClinicStore()

const invoicesRef = ref<HTMLElement | null>(null)
const wasIntersected = ref(false)
const userHasInvoiceWritePermission = computed(() =>
  userStore.hasPermission(Permission.ADD_INVOICE),
)

const { data, pending, refresh } = useLazyAsyncData(async () => {
  if (!wasIntersected.value) return

  // If it's the main page, get the first 3 invoices
  if (props.title) {
    return await nuxtApp.$api.getInvoices(0, 3).then((response) => {
      emit('refreshLayout')
      return response
    })
  }

  return await nuxtApp.$api.getInvoices(
    0,
    5,
    undefined,
    route.params.id.toString(),
  )
})

const { stop } = useIntersectionObserver(
  invoicesRef,
  ([{ isIntersecting }]) => {
    if (!isIntersecting) return

    wasIntersected.value = isIntersecting
    refresh()
    stop()
  },
)

watch(
  () => clinicStore.websocket.last_invoice_change,
  async () => {
    if (!pending.value) refresh()
  },
)
</script>

<template>
  <BaseCard ref="invoicesRef" :title="props.title ?? 'Faturas'">
    <template
      v-if="userHasInvoiceWritePermission && data?.count && !title"
      #headerIcon
    >
      <UButton
        icon="i-heroicons-plus-solid"
        label="Emitir"
        variant="soft"
        size="xs"
        :to="`/invoices/add/?patientId=${route.params.id}`"
      />
    </template>

    <template #content>
      <div v-if="pending && !data?.items" class="space-y-4">
        <USkeleton v-for="i in 3" :key="i" class="h-24" />
      </div>

      <NuxtLink
        v-else-if="!data?.count"
        :to="
          userHasInvoiceWritePermission
            ? `/invoices/add/?patientId=${route.params.id}`
            : '#'
        "
        class="transition-colors duration-300 hover:text-sky-500"
      >
        <BaseEmptyState
          icon="i-heroicons-banknotes"
          :title="
            props.title
              ? 'Não existem faturas.'
              : 'Não existem faturas associadas a este paciente.'
          "
          :description="
            userHasInvoiceWritePermission
              ? 'Emita uma fatura nova.'
              : 'Só um utilizador com as devidas permissões pode emitir novas faturas.'
          "
        />
      </NuxtLink>

      <div v-else class="flex flex-col">
        <div v-for="(invoice, index) in data.items" :key="invoice.id">
          <!-- create divider -->
          <hr v-if="index !== 0" />

          <NuxtLink
            :to="`/patients/${invoice.patient_id}/invoices/?id=${invoice.id}&type=${invoice.type}`"
          >
            <UiCardInvoiceItem :item="invoice" :show-patient-name="!!title" />
          </NuxtLink>
        </div>
      </div>
    </template>

    <template v-if="data?.count && !title" #footer>
      <UButton
        label="Todas as Faturas"
        variant="ghost"
        icon="i-heroicons-plus-solid"
        block
        color="white"
        :to="`/patients/${route.params.id}/invoices/`"
      />
    </template>
  </BaseCard>
</template>
